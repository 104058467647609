// TODO: adopt a more elaborate localization library, load the strings from an external file, and keep track of current locale so we use the right one
const displayTextMap = {
    account_type: {
        guest: 'Guest',
        individual: 'Individual',
        professional: 'Professional',
        household: 'Household',
        team: 'Team',
        company: 'Company',
        non_profit: 'Non-Profit',
        government: 'Government',
    },
    product_type: {
        clothing: 'Clothing',
        merchandise: 'Merchandise',
        donation: 'Donation',
        download: 'Download',
        membership: 'Membership',
        subscription: 'Subscription',
        saas: 'Software as a Service',
        /* 'saas-subscription', */
        /* 'saas-ticket', */
        ticket: 'Ticket',
        bundle: 'Bundle',
    },
};

function getDisplayText(key, value) {
    if (typeof key !== 'string') {
        throw new Error(`invalid key: ${JSON.stringify(key)}`);
    }
    if (typeof value !== 'string') {
        return '';
    }
    return displayTextMap[key]?.[value] ?? value;
}

export {
    getDisplayText,
};
